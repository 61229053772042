import { useRef, useEffect } from "react";
import rocketLogo from "../../../images/Rocket-Icon.png";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend
);
const SbLineChart = ({
  data,
  options,
  width,
  height,
  displayTimeMachineIcon,
}) => {
  const chartRef = useRef(null);
  useEffect(() => {
    const updatedData = () => {
      const chart = chartRef.current;
      if (data?.datasets?.length > 0) {
        if (displayTimeMachineIcon) {
          const pointLogo = new Image(50, 50);
          pointLogo.src = rocketLogo;
          chart.data.datasets[0]["pointStyle"] = pointLogo;
        }
      }
    };
    updatedData(); // eslint-disable-next-line
  }, [chartRef, data]);
  return (
    <div style={{ width: width || "100%", height: height }}>
      <Line ref={chartRef} data={data} options={options} />
    </div>
  );
};

export default SbLineChart;
