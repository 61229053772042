import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const SbPieChart = ({ data, options, width, height }) => {
  return (
    <div style={{ width: width || "100%", height: height || "100%" }}>
      <Pie data={data} options={options} />
    </div>
  );
};

export default SbPieChart;
