// import companyprofile from "../../images/Amazon_icon.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Tooltip from "@mui/material/Tooltip";
import sunShineIcon from "../../images/Metrics_Sunshine.png";
import cloudyIcon from "../../images/Metrics_Cloudy.png";
import thunderStromIcon from "../../images/Metrics_Thunderstorm.png";
import { CompanyProfile } from "../../models/StockProfile";
import SbAccordion from "../common/accordion/SbAccordion";
import { CompanyOutlookResponse } from "./model/companyoutlook/company-outlook-response";
import appUtils from "../../shared/utils";
import chartHelpers from "../utils/chart-helpers";
import { useEffect } from "react";
import useApi from "../../useApi";
import useState from "react-usestateref";
import { RatioChartData } from "./model/ratio-chart-data.mode";
import { HistoricalEarningsResponse } from "./model/historical-earnings-response.model";
import { Ratio } from "./model/ratio";
import SbLineChart from "../common/charts/sbLineChart";
import { Grid } from "@mui/material";
import { WeatherType } from "../utils/constants";
const StockProfileAccordians = (props: any) => {
  const stockProfileInfo = props?.stock as CompanyProfile;
  const companyOutlook = props?.companyOutlook as CompanyOutlookResponse;
  // eslint-disable-next-line
  const ratios: RatioChartData[] = [];
  // eslint-disable-next-line
  const [ratioChartData, setRatioChartData, ratioChartDataRef] =
    useState(ratios);
  // eslint-disable-next-line
  const historicalEarningsResponse: HistoricalEarningsResponse[] = [];
  // eslint-disable-next-line
  const [
    // eslint-disable-next-line
    historicalEarningsData,
    setHistoricalEarningsData,
    historicalEarningsDataRef,
  ] = useState(historicalEarningsResponse);
  // eslint-disable-next-line
  const ratioResponse: Ratio[] = [];
  // eslint-disable-next-line
  const [
    // eslint-disable-next-line
    ratioData,
    setRatioData,
    ratioDataRef,
  ] = useState(ratioResponse);

  // eslint-disable-next-line
  const [hitMissChartData, setHitMissChartData, hitMissChartDataRef] = useState(
    [] as any[]
  );
  // eslint-disable-next-line
  const { sendRequest } = useApi();
  const {
    assetsVsLiabilitiesOptions,
    incomeStatementRevenueOptions,
    incomeStatementNetIncomeOptions,
    createIncomeStatementChart,
    createAssetsVsLiabilitiesChart,
    epsEarningsOptions,
    compareEpsVsEstimatedChart,
  } = chartHelpers();
  const {
    getNumber,
    formatToTwoDecimal,
    convertToInternationalCurrencySystem,
    getYear,
    nullNumberHandler,
  } = appUtils();
  useEffect(() => {
    const handleGetApiCall = async <T,>(
      path: string,
      callback: (data: T) => void
    ) => {
      try {
        const response = await sendRequest(path);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        callback(data);
      } catch (error) {}
    };
    const getRationChart = () => {
      handleGetApiCall<RatioChartData[]>(
        "/api/stockProfileDetails/ratioChart?symbol=" +
          stockProfileInfo?.Symbol,
        (data) => {
          setRatioChartData(data);
        }
      );
    };
    const getEarnings = () => {
      handleGetApiCall<HistoricalEarningsResponse[]>(
        "/api/stockProfileDetails/earnings/5?symbol=" +
          stockProfileInfo?.Symbol,
        (data) => {
          setHistoricalEarningsData(data);
        }
      );
    };
    const getProfit = () => {
      handleGetApiCall<Ratio[]>(
        "/api/stockProfileDetails/ratios/5?symbol=" + stockProfileInfo?.Symbol,
        (data) => {
          setRatioData(data);
        }
      );
    };
    getRationChart();
    getProfit();
    getEarnings();
    // eslint-disable-next-line
  }, []);
  // Accordion Data
  const stockLongTermReturnAccordianTitle = () => {
    if (stockProfileInfo?.metrics?.returns === WeatherType.thunderstorm) {
      return (
        <Tooltip
          title="Did we just move to Florida? It's severe thunderstorm here. Watchout!"
          arrow
        >
          <img src={thunderStromIcon} alt="" width="44" height="44" />
        </Tooltip>
      );
    }
    if (stockProfileInfo?.metrics?.returns === WeatherType.cloudy) {
      return (
        <Tooltip
          title="Bit gloomy like Seattle. You never know if its going to clear up or its going to pour."
          arrow
        >
          <img src={cloudyIcon} alt="" width="44" height="44" />
        </Tooltip>
      );
    }
    return (
      <Tooltip title="It's all bright and sunny here! Like in Texas." arrow>
        <img src={sunShineIcon} alt="" width="44" height="44" />
      </Tooltip>
    );
  };
  const areTheyProfitableAccordianTitle = () => {
    if (stockProfileInfo?.metrics?.profitability === WeatherType.sunny) {
      return (
        <Tooltip title="It's all bright and sunny here! Like in Texas." arrow>
          <img src={sunShineIcon} alt="" width="44" height="44" />
        </Tooltip>
      );
    }
    if (stockProfileInfo?.metrics?.profitability === WeatherType.cloudy) {
      return (
        <Tooltip
          title="Bit gloomy like Seattle. You never know if its going to clear up or its going to pour."
          arrow
        >
          <img src={cloudyIcon} alt="" width="44" height="44" />
        </Tooltip>
      );
    }
    return (
      <Tooltip
        title="Did we just move to Florida? It's severe thunderstorm here. Watchout!"
        arrow
      >
        <img src={thunderStromIcon} alt="" width="44" height="44" />
      </Tooltip>
    );
  };
  const areTheyGrowingAccordinTitle = () => {
    if (stockProfileInfo?.metrics?.growth === WeatherType.cloudy) {
      return (
        <Tooltip
          title="Bit gloomy like Seattle. You never know if its going to clear up or its going to pour."
          arrow
        >
          <img src={cloudyIcon} alt="" width="44" height="44" />
        </Tooltip>
      );
    }
    if (stockProfileInfo?.metrics?.growth === WeatherType.thunderstorm) {
      return (
        <Tooltip
          title="Did we just move to Florida? It's severe thunderstorm here. Watchout!"
          arrow
        >
          <img src={thunderStromIcon} alt="" width="44" height="44" />
        </Tooltip>
      );
    }
    return (
      <Tooltip title="It's all bright and sunny here! Like in Texas." arrow>
        <img src={sunShineIcon} alt="" width="44" height="44" />
      </Tooltip>
    );
  };
  const doTheyHaveCashAccordianTitle = () => {
    if (stockProfileInfo?.metrics?.cash === WeatherType.cloudy) {
      return (
        <Tooltip
          title="Bit gloomy like Seattle. You never know if its going to clear up or its going to pour."
          arrow
        >
          <img src={cloudyIcon} alt="" width="44" height="44" />
        </Tooltip>
      );
    }
    if (stockProfileInfo?.metrics?.cash === WeatherType.thunderstorm) {
      return (
        <Tooltip
          title="Did we just move to Florida? It's severe thunderstorm here. Watchout!"
          arrow
        >
          <img src={thunderStromIcon} alt="" width="44" height="44" />
        </Tooltip>
      );
    }
    return (
      <Tooltip title="It's all bright and sunny here! Like in Texas." arrow>
        <img src={sunShineIcon} alt="" width="44" height="44" />
      </Tooltip>
    );
  };

  const hitMissAccordianTitle = () => {
    if (stockProfileInfo?.metrics?.earnings === WeatherType.sunny) {
      return (
        <Tooltip title="It's all bright and sunny here! Like in Texas." arrow>
          <img src={sunShineIcon} alt="" width="44" height="44" />
        </Tooltip>
      );
    }
    if (stockProfileInfo?.metrics?.earnings === WeatherType.cloudy) {
      return (
        <Tooltip
          title="Bit gloomy like Seattle. You never know if its going to clear up or its going to pour."
          arrow
        >
          <img src={cloudyIcon} alt="" width="44" height="44" />
        </Tooltip>
      );
    }
    return (
      <Tooltip
        title="Did we just move to Florida? It's severe thunderstorm here. Watchout!"
        arrow
      >
        <img src={thunderStromIcon} alt="" width="44" height="44" />
      </Tooltip>
    );
  };

  const stockReturnsContent = () => {
    const priceCAGR = stockProfileInfo?.priceCAGR || 0;
    return (
      <>
        <h5 className="primary-header-h5">Stock Price</h5>
        <ul>
          <li>
            {stockProfileInfo.companyName}'s stock price
            {priceCAGR > 0 ? "grew" : "dropped"} on an average of
            <span className={priceCAGR > 0 ? "stock-profit-txt" : "stock-loss-txt"}>
              {formatToTwoDecimal(priceCAGR)}%
            </span>{" "}
            year on year for the past 5 years which means a{" "}
            {stockProfileInfo?.currency} 1000 invested in{" "}
            {stockProfileInfo.companyName} in {getYear(5)} would have
            {stockProfileInfo?.priceCAGR > 0 ? " grown" : " dropped"} to
            {stockProfileInfo?.currency}{" "}
            {formatToTwoDecimal(
              (1000 / stockProfileInfo?.prices?.begin?.close) *
                stockProfileInfo?.prices?.end?.close
            )}{" "}
            today
          </li>
        </ul>
      </>
    );
  };

  const isStockProfitableContent = () => {
    return (
      <div>
        <h5 className="primary-header-h5">
          {stockProfileInfo?.companyName}
          {stockProfileInfo?.annualRatios?.netProfitMargin > 0
            ? " is Profitable!"
            : " is makinga loss "}
        </h5>
        <ul>
          <li>
            {stockProfileInfo?.companyName}{" "}
            {stockProfileInfo?.annualRatios?.netProfitMargin > 0
              ? "made a profit of "
              : "made a loss of "}
            <span className={isProfitable() ? "stock-profit-txt" : "stock-loss-txt"}>
              {formatToTwoDecimal(
                stockProfileInfo?.annualRatios?.netProfitMargin * 100
              )}
              %
            </span>
             last year
          </li>
          {stockProfileInfo?.annualRatios?.operatingProfitMargin > 0 && (
            <li>
              For every {stockProfileInfo?.currency} of sale{" "}
              {stockProfileInfo?.companyName} makes a{" "}
              {isProfitable() ? "profit" : "loss"} of{" "}
              <span className={isProfitable() ? "stock-profit-txt" : "stock-loss-txt"}>
                {stockProfileInfo?.currency}{" "}
                {formatToTwoDecimal(
                  stockProfileInfo?.annualRatios?.operatingProfitMargin
                )}
              </span>
            </li>
          )}
        </ul>
        {stockProfileInfo?.LastDiv > 0 && (
          <h5 className="primary-header-h5">
            <span className="primary-header-h5">Dividends</span>
          </h5>
        )}
        <ul>
          {stockProfileInfo?.LastDiv > 0 && (
            <span>
              <li>
                {stockProfileInfo.companyName} pays cash just for owning their
                stocks. Yes, they call it dividends!
              </li>
              <ul>
                <li>
                  {stockProfileInfo.companyName} paid out a dividend of
                  <span className="stock-profit-txt">
                    {" "}
                    {stockProfileInfo?.currency}{" "}
                    {formatToTwoDecimal(stockProfileInfo?.LastDiv)}{" "}
                  </span>
                  for every stock which is about
                  {stockProfileInfo?.LastDiv && stockProfileInfo?.Price
                    ? formatToTwoDecimal(
                        (stockProfileInfo?.LastDiv / stockProfileInfo?.Price) *
                          100
                      )
                    : 0.0}
                  % of it’s stock value at that time
                </li>
              </ul>
            </span>
          )}

          {stockProfileInfo?.annualRatios?.dividendYield <= 0 && (
            <li>Tough luck! They don’t pay dividends</li>
          )}
        </ul>
      </div>
    );
  };
  const isStockGrowingContent = () => {
    return (
      <div>
        <h5 className="primary-header-h5">
          {stockProfileInfo?.companyName}{" "}
          {companyOutlook?.financialsAnnual &&
          companyOutlook?.financialsAnnual &&
          companyOutlook?.financialsAnnual?.income &&
          nullNumberHandler(
            companyOutlook?.financialsAnnual?.income[0]?.revenue
          ) >
            nullNumberHandler(
              companyOutlook?.financialsAnnual?.income[1]?.revenue
            )
            ? " is growing!"
            : " is not growing!"}
        </h5>
        <ul>
          <li>
            {stockProfileInfo?.companyName}. generated a revenue of
            {stockProfileInfo?.currency}{" "}
            {convertToInternationalCurrencySystem(
              stockProfileInfo?.annualStatements?.[0]?.revenue
            )}{" "}
            in {stockProfileInfo?.annualStatements?.[0]?.calendarYear} from{" "}
            {stockProfileInfo?.currency}
            {convertToInternationalCurrencySystem(
              stockProfileInfo?.annualStatements?.[1]?.revenue
            )}{" "}
            in{" "}
            {parseInt(
              companyOutlook?.financialsAnnual?.income?.[0]?.calendarYear
            ) - 1}{" "}
            which is{" "}
            {stockProfileInfo?.revenueCAGR?.CAGR1Yr > 0
              ? "an increase of "
              : "a drop of "}
            <span
              className={
                stockProfileInfo?.revenueCAGR?.CAGR1Yr > 0
                  ? "stock-profit-txt"
                  : "stock-loss-txt"
              }
            >
              {formatToTwoDecimal(
                (nullNumberHandler(
                  companyOutlook?.financialsAnnual?.income?.[0]?.revenue -
                    nullNumberHandler(
                      companyOutlook?.financialsAnnual?.income?.[1]?.revenue
                    )
                ) /
                  ((nullNumberHandler(
                    companyOutlook?.financialsAnnual?.income?.[0]?.revenue
                  ) +
                    nullNumberHandler(
                      companyOutlook?.financialsAnnual?.income?.[1]?.revenue
                    )) /
                    2)) *
                  100
              )}
              %
            </span>
          </li>
          {stockProfileInfo?.revenueCAGR?.CAGR3Yr > 0 &&
            stockProfileInfo?.revenueCAGR?.CAGR5Yr > 0 && (
              <li>
                Also, their revenue grew by an average of {" "}
                <span className={"stock-profit-txt"}>{formatToTwoDecimal(2)}%</span>{" "}
                over 3 years and
                <span className={"stock-profit-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.revenueCAGR?.CAGR5Yr)}%{" "}
                </span>
                 over 5 years
              </li>
            )}
          {stockProfileInfo?.revenueCAGR?.CAGR3Yr <= 0 &&
            stockProfileInfo?.revenueCAGR?.CAGR5Yr > 0 && (
              <li>
                Also, their revenue dropped by an average of{" "}
                <span className={"stock-loss-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.revenueCAGR?.CAGR3Yr)}%{" "}
                </span>
                over 3 years and{" "}
                <span className={"stock-profit-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.revenueCAGR?.CAGR5Yr)}%{" "}
                </span>
                 over 5 years
              </li>
            )}
          {stockProfileInfo?.revenueCAGR?.CAGR3Yr > 0 &&
            stockProfileInfo?.revenueCAGR?.CAGR5Yr <= 0 && (
              <li>
                Also, their revenue grew by an average of
                <span className={"stock-profit-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.revenueCAGR?.CAGR3Yr)}%{" "}
                </span>
                over 3 years and
                <span className={"stock-loss-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.revenueCAGR?.CAGR5Yr)}%{" "}
                </span>
                 over 5 years
              </li>
            )}
          {stockProfileInfo?.revenueCAGR?.CAGR3Yr <= 0 &&
            stockProfileInfo?.revenueCAGR?.CAGR5Yr <= 0 && (
              <li>
                Also, their revenue dropped by an average of
                <span className={"stock-loss-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.revenueCAGR?.CAGR3Yr)}%{" "}
                </span>
                over 3 years and
                <span className={"stock-loss-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.revenueCAGR?.CAGR5Yr)}%{" "}
                </span>
                 over 5 years
              </li>
            )}
        </ul>
        <h5 className="primary-header-h5">Profits</h5>
        <ul>
          <li>
            {stockProfileInfo?.companyName}'s profits
            {stockProfileInfo?.netIncomeCAGR?.CAGR1Yr > 0 ? (
              <span>
                {" grew by "}
                <label className={"stock-profit-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.netIncomeCAGR?.CAGR1Yr)}{" "}
                  %
                </label>
              </span>
            ) : (
              <span>
                {" dropped by "}
                <label className={"stock-loss-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.netIncomeCAGR?.CAGR1Yr)}
                  %
                </label>{" "}
              </span>
            )}
            in the last year
          </li>
          {stockProfileInfo?.netIncomeCAGR?.CAGR3Yr > 0 &&
            stockProfileInfo?.netIncomeCAGR?.CAGR5Yr > 0 && (
              <li>
                Also, their profits grew by an average of{" "}
                <span className={"stock-profit-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.netIncomeCAGR?.CAGR3Yr)}
                  %
                </span>{" "}
                over 3 years and{" "}
                <span className={"stock-profit-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.netIncomeCAGR?.CAGR5Yr)}
                  %
                </span>{" "}
                 over 5 years
              </li>
            )}

          {stockProfileInfo?.netIncomeCAGR?.CAGR3Yr <= 0 &&
            stockProfileInfo?.netIncomeCAGR?.CAGR5Yr > 0 && (
              <li>
                Also, their profits dropped by an average of{" "}
                <span className={"stock-loss-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.netIncomeCAGR?.CAGR3Yr)}
                  %{" "}
                </span>{" "}
                over 3 years and{" "}
                <span className={"stock-profit-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.netIncomeCAGR?.CAGR5Yr)}
                  %{" "}
                </span>
              </li>
            )}
          {stockProfileInfo?.netIncomeCAGR?.CAGR3Yr > 0 &&
            stockProfileInfo?.netIncomeCAGR?.CAGR5Yr <= 0 && (
              <li>
                Also, their profits grew by an average of{" "}
                <span className={"stock-profit-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.netIncomeCAGR?.CAGR3Yr)}
                  %
                </span>{" "}
                over 3 years and{" "}
                <span className={"stock-loss-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.netIncomeCAGR?.CAGR5Yr)}
                  %
                </span>{" "}
                 over 5 years
              </li>
            )}
          {stockProfileInfo?.netIncomeCAGR?.CAGR3Yr <= 0 &&
            stockProfileInfo?.netIncomeCAGR?.CAGR5Yr <= 0 && (
              <li>
                Also, their profits dropped by an average of{" "}
                <span className={"stock-loss-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.netIncomeCAGR?.CAGR3Yr)}
                  %
                </span>{" "}
                over 3 years and{" "}
                <span className={"stock-loss-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.revenueCAGR?.CAGR5Yr)}%
                </span>{" "}
                 over 5 years
              </li>
            )}
        </ul>
        {getIncomeStatementChart()}
      </div>
    );
  };
  const doTheyHaveCashContent = () => {
    return (
      <div>
        <h5 className="primary-header-h5">
          {" "}
          {stockProfileInfo?.annualRatios?.currentRatio >= 1 ? "Yes" : "No"}
        </h5>
        <ul>
          {stockProfileInfo?.annualRatios?.currentRatio >= 1 && (
            <span>
              <li>
                {stockProfileInfo?.annualRatios?.currentRatio >= 1
                  ? "Yes!"
                  : "No!"}
                {stockProfileInfo.companyName} has enough cash and assets to pay
                for their liabilities in the next 1 year
              </li>
              <li>
                For every {stockProfileInfo?.currency} 1{" "}
                {stockProfileInfo?.currency} they owe in the next 12 months,
                they only have
                <span className={"stock-profit-txt"}>
                  {formatToTwoDecimal(
                    stockProfileInfo?.annualRatios?.currentRatio
                  )}
                </span>
                to pay
              </li>
            </span>
          )}
          {stockProfileInfo?.annualRatios?.currentRatio < 1 && (
            <span>
              <li>
                {stockProfileInfo.companyName} does not have enough cash and
                assets to pay for their liabilities in the next 1 year
              </li>
              <li>
                For every {stockProfileInfo?.currency} 1 they owe in the next 12
                months, they only have {stockProfileInfo?.currency}{" "}
                <span className={"stock-loss-txt"}>
                  {formatToTwoDecimal(
                    stockProfileInfo?.annualRatios?.currentRatio
                  )}
                </span>
                to pay
              </li>
            </span>
          )}
          {stockProfileInfo?.annualRatios?.debtEquityRatio > 1 && (
            <li>
              {stockProfileInfo.companyName} relies more on borrowed money to
              fund their operations as compared to money raised from the stocks
              that they have issued.
            </li>
          )}
          {stockProfileInfo?.annualRatios?.debtEquityRatio === 1 && (
            <li>
              {stockProfileInfo.companyName} relies equally on borrowed money
              and money raised from the stocks to fund their operations.
            </li>
          )}
          {stockProfileInfo?.annualRatios?.debtEquityRatio > 0 &&
            stockProfileInfo?.annualRatios?.debtEquityRatio < 1 && (
              <li>
                {stockProfileInfo.companyName} relies on money raised from sale
                of stocks more than borrowed money to fund their operations.
              </li>
            )}
          {stockProfileInfo?.annualRatios?.debtEquityRatio < 0 && (
            <li>
              {stockProfileInfo.companyName} relies on borrowed money more than
              the sale of their stocks.
            </li>
          )}
          {stockProfileInfo?.annualRatios?.debtEquityRatio >= 0 && (
            <li>
              <span className={"stock-profit-txt"}>
                {formatToTwoDecimal(
                  (stockProfileInfo?.annualRatios?.debtEquityRatio /
                    (1 + stockProfileInfo?.annualRatios?.debtEquityRatio)) *
                    100
                )}
                %{" "}
              </span>
              of funding comes from borrowed money and
              <span className={"stock-profit-txt"}>
                {formatToTwoDecimal(
                  100 -
                    (stockProfileInfo?.annualRatios?.debtEquityRatio /
                      (1 + stockProfileInfo?.annualRatios?.debtEquityRatio)) *
                      100
                )}
                %{" "}
              </span>
              from the sale of stocks
            </li>
          )}
          {stockProfileInfo?.annualRatios?.debtEquityRatio < 0 && (
            <li>
              Their liabilities are
              <span className={"stock-loss-txt"}>
                {formatToTwoDecimal(
                  Math.abs(
                    100 -
                      (stockProfileInfo?.annualRatios?.debtEquityRatio /
                        (1 + stockProfileInfo?.annualRatios?.debtEquityRatio)) *
                        100
                  )
                )}
                %
              </span>
              more than their shareholder equity
            </li>
          )}
        </ul>

        <ul>
          {cashSituation() !== "Cloudy" && <span>And Also</span>}
          {cashSituation() === "Cloudy" && (
            <span>
              {getNumber(stockProfileInfo?.score?.totalAssets) >=
                getNumber(stockProfileInfo?.score?.totalLiabilities) && (
                <span>
                  <strong>But no need to panic. Because..</strong>
                </span>
              )}
              {getNumber(stockProfileInfo?.score?.totalAssets) <
                getNumber(stockProfileInfo?.score?.totalLiabilities) && (
                <span>But ,</span>
              )}
            </span>
          )}
          {getNumber(stockProfileInfo?.score?.totalAssets) >
            getNumber(stockProfileInfo?.score?.totalLiabilities) && (
            <li>
              {stockProfileInfo.companyName}'s long term assets{" "}
              <span className={"stock-profit-txt"}>
                {convertToInternationalCurrencySystem(
                  stockProfileInfo?.score?.totalAssets
                )}
              </span>
              are more than their liabilities{" "}
              <span className={"stock-profit-txt"}>
                {convertToInternationalCurrencySystem(
                  stockProfileInfo?.score?.totalLiabilities
                )}
              </span>
            </li>
          )}
          {getNumber(stockProfileInfo?.score?.totalAssets) <=
            getNumber(stockProfileInfo?.score?.totalLiabilities) && (
            <li>
              {stockProfileInfo.companyName}'s long term liabilities{" "}
              <span className={"stock-loss-txt"}>
                (
                {convertToInternationalCurrencySystem(
                  stockProfileInfo?.score?.totalLiabilities
                )}
                )
              </span>
              are more than their assets{" "}
              <span className={"stock-loss-txt"}>
                (
                {convertToInternationalCurrencySystem(
                  stockProfileInfo?.score?.totalAssets
                )}
                )
              </span>
            </li>
          )}
        </ul>
        {getAssetLiabilityChart()}
      </div>
    );
  };
  const earningHitMissRatioContent = () => {
    return (
      <div>
        {getTopValidEps(historicalEarningsDataRef.current)?.eps >=
          getTopValidEps(historicalEarningsDataRef.current)?.epsEstimated && (
          <ul>
            <span style={{ fontWeight: "bolder" }}>
              {" "}
              Bullseye - It's a Hit!
            </span>
            <li>
              They make a profit (earnings per share or eps) of{" "}
              <span className="stock-profit-txt">
                {stockProfileInfo?.currency}{" "}
                {formatToTwoDecimal(
                  getTopValidEps(historicalEarningsDataRef.current)?.eps
                )}
              </span>{" "}
              for every share owned by their shareholders
            </li>
            <li>
              Their earnings per share last quarter Hit their target{" "}
              <span className="stock-profit-txt">
                {stockProfileInfo?.currency}{" "}
                {formatToTwoDecimal(
                  getTopValidEps(historicalEarningsDataRef.current)?.eps
                )}
              </span>{" "}
              vs{" "}
              <span className="stock-profit-txt">
                {stockProfileInfo?.currency}{" "}
                {formatToTwoDecimal(
                  getTopValidEps(historicalEarningsDataRef.current)
                    ?.epsEstimated
                )}
              </span>{" "}
            </li>
          </ul>
        )}
        {getTopValidEps(historicalEarningsDataRef.current)?.eps <
          getTopValidEps(historicalEarningsDataRef.current)?.epsEstimated && (
          <ul>
            <span style={{ fontWeight: "bolder" }}>
              {" "}
              Off-target - It's a Miss!
            </span>
            <li>
              They make a profit (earnings per share or eps) of{" "}
              <span className="stock-loss-txt">
                {stockProfileInfo?.currency}{" "}
                {formatToTwoDecimal(
                  getTopValidEps(historicalEarningsDataRef.current)?.eps
                )}
              </span>{" "}
              for every share owned by their shareholders
            </li>
            <li>
              However, it was lesser than what they expected to achieve{" "}
              <span className="stock-profit-txt">
                {stockProfileInfo?.currency}{" "}
                {formatToTwoDecimal(
                  getTopValidEps(historicalEarningsDataRef.current)?.eps
                )}
              </span>{" "}
              vs{" "}
              <span className="stock-profit-txt">
                {stockProfileInfo?.currency}{" "}
                {formatToTwoDecimal(
                  getTopValidEps(historicalEarningsDataRef.current)
                    ?.epsEstimated
                )}
              </span>{" "}
              last quarter{" "}
            </li>
          </ul>
        )}
        {getEpsEarningsChart()}
      </div>
    );
  };
  const getTopValidEps = (earnings: HistoricalEarningsResponse[]) => {
    if (!earnings || earnings?.length === 0) {
      return { eps: 0, epsEstimated: 0 };
    }
    // Added below condition x.eps != 0 as we are getting eps value as 0 in some cases instead of null
    let index = earnings.findIndex(
      (x: { eps: number | null }) => x.eps !== null && x.eps !== 0
    );
    return index === -1 ? null : earnings[index];
  };
  const isProfitable = (): boolean =>
    nullNumberHandler(stockProfileInfo?.annualRatios?.netProfitMargin) > 0;
  const cashSituation = () => {
    const currentRatios = stockProfileInfo?.annualRatios;
    const score = stockProfileInfo?.score;
    if (currentRatios && score) {
      if (
        currentRatios.currentRatio >= 1 &&
        score.totalAssets >= score.totalLiabilities
      ) {
        return "Sunshine";
      } else if (
        currentRatios.currentRatio >= 1 &&
        score.totalAssets < score.totalLiabilities
      ) {
        return "Cloudy";
      } else if (
        currentRatios.currentRatio < 1 &&
        score.totalAssets >= score.totalLiabilities
      ) {
        return "Cloudy";
      } else if (
        currentRatios.currentRatio < 1 &&
        score.totalAssets < score.totalLiabilities
      ) {
        return "Thunderstorm";
      }
    }
    return null;
  };
  const getIncomeStatementChart = () => {
    if (
      !companyOutlook?.financialsAnnual?.income ||
      companyOutlook?.financialsAnnual?.income?.length === 0
    ) {
      return <div></div>;
    }
    const resultData = createIncomeStatementChart(
      companyOutlook?.financialsAnnual?.income
    );
    return (
      <Grid container spacing={2}>
        <Grid item={true} xs={12} md={6} lg={6}>
          <SbLineChart
            data={resultData?.revenueData}
            options={incomeStatementRevenueOptions()}
            width=""
            height="400px"
            displayTimeMachineIcon={false}
          />
        </Grid>
        <Grid item={true} xs={12} md={6} lg={6}>
          <SbLineChart
            data={resultData?.incomeData}
            options={incomeStatementNetIncomeOptions()}
            width=""
            height="400px"
            displayTimeMachineIcon={false}
          />
        </Grid>
      </Grid>
    );
  };
  const getAssetLiabilityChart = () => {
    if (!ratioDataRef?.current || ratioDataRef?.current?.length === 0) {
      return <div></div>;
    }
    const assetData = createAssetsVsLiabilitiesChart(ratioDataRef?.current);

    return (
      <div>
        <p style={{ left: "20%" }}> Short-term Assets vs Liabilities</p>
        <p>
          <small>
            (If Current Ratio &lt 1 : Assets &gt liabilities; else Liabilities
            &gt Assets)
          </small>
        </p>
        <SbLineChart
          data={assetData}
          options={assetsVsLiabilitiesOptions()}
          width=""
          height="400px"
          displayTimeMachineIcon={false}
        />
      </div>
    );
  };
  const getEpsEarningsChart = () => {
    if (
      !historicalEarningsDataRef?.current ||
      historicalEarningsDataRef?.current?.length === 0
    ) {
      return <div></div>;
    }
    const chartData = compareEpsVsEstimatedChart(
      historicalEarningsDataRef?.current
    );
    return (
      <SbLineChart
        data={chartData}
        options={epsEarningsOptions()}
        width="100%"
        height="300px"
        displayTimeMachineIcon={false}
      />
    );
  };
  const accordionItems = [
    {
      title: "Is the stock giving long term returns?",
      content: stockReturnsContent(),
      icons: [stockLongTermReturnAccordianTitle()],
    },
    {
      title: "Are they profitable?",
      content: isStockProfitableContent(),
      icons: [areTheyProfitableAccordianTitle()],
    },
    {
      title: "Are they growing?",
      content: isStockGrowingContent(),
      icons: [areTheyGrowingAccordinTitle()],
    },
    {
      title: "Do they have cash?",
      content: doTheyHaveCashContent(),
      icons: [doTheyHaveCashAccordianTitle()],
    },
    {
      title: "Earnings Hit or Miss",
      content: earningHitMissRatioContent(),
      icons: [hitMissAccordianTitle()],
    },
  ];

  return <SbAccordion items={accordionItems} />;
};

export default StockProfileAccordians;
