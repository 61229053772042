import { Grid, Typography } from "@mui/material";
import companyprofile from "../../images/SCHD.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CompanyProfile } from "../../models/StockProfile";
import StockHistoryChart from "./stock-history-chart";
import useState from "react-usestateref";
import appUtils from "../../shared/utils";
import EtfStockProfileAccordians from "./etf-stock-profile-accordians";
import { useEffect } from "react";
import useApi from "../../useApi";
import { EtfInfo } from "./model/etf-info";
import AddRemoveFavourite from "../common/addRemoveFavourite";
import EtfPeers from "./etf-peers";
import { ChartToolTip } from "./model/chart-tooltip-change.model";
import { TimeMachine } from "./timeMachine";
import Tooltip from "@mui/material/Tooltip";
import React from "react";

const EtfProfile = (props: any) => {
  const { formatToTwoDecimal } = appUtils();

  const [etfData, setEtfData] = useState<EtfInfo[]>([]);
  const [chartToolTip, setChartToolTip] = useState<ChartToolTip>({
    label: new Date().toLocaleString(),
    formattedValue: "0",
  });

  const stockProfileInfo: CompanyProfile = props?.stock as CompanyProfile;
  const [isFavourite, setIsFavourite] = useState<boolean>(
    Boolean(stockProfileInfo?.isFavorite)
  );
  const { sendRequest } = useApi();

  useEffect(() => {
    const handleGetApiCall = async <T,>(
      path: string,
      callback: (data: T) => void
    ) => {
      try {
        const response = await sendRequest(path);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        callback(data);
      } catch (error) {}
    };
    const getEtfData = () => {
      handleGetApiCall<EtfInfo[]>(
        "/api/stockProfileDetails/etf-data?symbol=" + stockProfileInfo?.Symbol,
        (data) => {
          setEtfData(data);
        }
      );
    };

    getEtfData(); // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  const addStockToFavourite = () => {
    setIsFavourite(true);
  };

  const removeStockFromFavourite = () => {
    setIsFavourite(false);
  };

  const getInceptionDate = () => {
    if (!etfData[0] || !etfData[0]?.inceptionDate) {
      return "";
    }
    var parts = etfData[0]?.inceptionDate?.split("-");
    // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
    // January - 0, February - 1, etc.
    var mydate = new Date(
      parseInt(parts[0]),
      parseInt(parts[2]) - 1,
      parseInt(parts[1])
    );
    const inceptionDateFormated = new Date(mydate);
    return inceptionDateFormated.toDateString();
  };

  function onToolTipChange(data: ChartToolTip) {
    if (
      chartToolTip?.label !== data?.label ||
      chartToolTip?.formattedValue !== data?.formattedValue
    ) {
      setChartToolTip(data);
    }
  }

  return (
    <>
      <div className="p-3">
        <Grid container>
          <Grid item={true} xs={12} md={6} lg={8}>
            <div className="d-flex align-items-center justify-content-center flex-column flex-md-row justify-content-md-start mb-3">
              <div className="flex-shrink-0">
                <img
                  src={companyprofile}
                  alt="Company Profile"
                  className="object-fit-contain"
                  width="120"
                  height="120"
                />
              </div>
              <div className="flex-grow-1 ms-3">
                <div className="d-flex align-items-center gap-2 my-2 justify-content-start">
                  <Tooltip title={stockProfileInfo?.companyName} placement="bottom" arrow>
                    <div className="primary-header header-txt-truncate">{stockProfileInfo?.companyName}. Dividend Equity ETF</div> 
                  </Tooltip>
                  <AddRemoveFavourite
                    stock={stockProfileInfo}
                    backgroundColor={"#ffff"}
                    isFavorite={isFavourite}
                    addToFavouriteStock={addStockToFavourite}
                    unFavouriteStock={removeStockFromFavourite}>
                  </AddRemoveFavourite>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item={true} xs={12} md={6} lg={4}>
            <div className="d-flex flex-column justify-content-center justify-content-md-end justify-content-lg-end align-items-center align-items-md-end align-items-ld-end">
              <div className="primary-header mb-1">
                {formatToTwoDecimal(stockProfileInfo?.Price)}{" "}
                {stockProfileInfo?.currency}
              </div>
              <div className="stock-value-header mb-1">
                <span className="positive">{stockProfileInfo?.Changes}</span>(
                {formatToTwoDecimal(stockProfileInfo?.ChangesPercentage)}%){" "}
                {stockProfileInfo?.ipoDate}
              </div>
              <div className="secondary-header mb-1">
                <span>{stockProfileInfo?.exchangeShortName}</span> :{" "}
                <span>{stockProfileInfo?.Symbol}</span>
              </div>
            </div>
          </Grid>
        </Grid>

        <div className="mb-3">
          <StockHistoryChart
            symbol={stockProfileInfo?.Symbol}
            onToolTipChange={onToolTipChange}
          ></StockHistoryChart>
        </div>
        <div className="d-flex justify-content-center">
          <div className="d-flex justify-content-center align-items-center mb-3">
            <div className="flex-grow-1 ms-3">
              <TimeMachine
                currentStockPrice={stockProfileInfo?.Price}
                chartToolTip={chartToolTip}
                currency={stockProfileInfo?.currency}
                ipoDate={stockProfileInfo?.ipoDate}
              />
            </div>
          </div>
        </div>

        <div className="">
          <Typography variant="h5" className="mb-2">
            About
          </Typography>
          <ul className="list-unstyled">
            <li>
              <span className="fw-medium pe-2">
                Inception Date: {getInceptionDate()}
              </span>
            </li>
          </ul>
          <div className="primary-txt-16 mb-3">
            {stockProfileInfo?.description}
          </div>
        </div>

        <EtfStockProfileAccordians stock={stockProfileInfo} />
        <EtfPeers symbol={stockProfileInfo?.Symbol}></EtfPeers>
      </div>
    </>
  );
};

export default EtfProfile;
