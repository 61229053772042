import {
  SignInButton,
  SignUpButton,
  SignedOut,
  useUser,
} from "@clerk/clerk-react";
import Container from "react-bootstrap/Container";
import Superhero from "../../assets/girlpizzaeatinganime.svg";
import msStartup from "../../assets/msStartup.svg";
import Logo from "../../images/logo-transparent.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Dialog, DialogContent, Grid, IconButton } from "@mui/material";
import { TypeAnimation } from "react-type-animation";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { useLocation } from "react-router-dom";
import { ApplicationRoutes } from "../../constants/application-routes";
function Landing() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const location = useLocation();
  const [redirectUrl, setRedirectUrl] = useState(
    ApplicationRoutes.Home.toString()
  );
  const { isSignedIn, user } = useUser();
  const navigate = useNavigate();
  useEffect(() => {
    const redirectUrAfterLogin = location?.state
      ? location?.state
      : ApplicationRoutes.Home.toString();
    setRedirectUrl(redirectUrAfterLogin);
    if (isSignedIn && user !== null) {
      navigate(redirectUrl);
    } // eslint-disable-next-line
  }, [isSignedIn, user, navigate]);
  return (
    <div>
      <div className="sb-landing-wrap">
        <Container>
          <Grid container className="logo-wrap">
            <Grid item md={4} sm={12}>
              <img src={Logo} className="landing-logo" alt="logo" width={233} />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="sb-main-content"
          >
            <Grid item md={7} sm={12}>
              {/* <h1 className="landing-tagline-primary">
                  {" "}
                  Creating stock market superheroes!
                </h1> */}
              <div className="mb-4">
                <TypeAnimation
                  preRenderFirstString={true}
                  sequence={[
                    // Same substring at the start will only be typed once, initially
                    "Discover Stocks While You Enjoy Your Pizza!",
                    1000,
                    "Discover Stocks While You Brew Your Coffee!",
                    1000,
                    "Discover Stocks While You Wait for Uber!",
                    1000,
                    "Discover Stocks While You Brush Your Teeth!",
                    1000,
                  ]}
                  speed={50}
                  className="text-render"
                  repeat={Infinity}
                />
              </div>
              <Grid>
                <Grid item md={12} sm={12}>
                  <div className="mb-4">
                    <p className="landing-tagline-secondary mb-1">
                      Quick, Easy, and Powerful stock analysis for Beginners.
                    </p>
                    <p className="landing-tagline-secondary mb-1">
                      Try us today. It’s Free. And No Credit Card Required.
                    </p>
                  </div>
                </Grid>
              </Grid>
              <div className="d-flex gap-3 align-items-center justify-content-center justify-content-md-center justify-content-lg-start">
                <SignedOut>
                  <SignUpButton
                    redirectUrl={redirectUrl}
                    children={
                      <div className="">
                        {/* <button className="signin-btn">Join for free</button> */}
                        <button className="primary-variant">
                          Join for free
                        </button>
                      </div>
                    }
                  />
                </SignedOut>
                {/* <SignedIn>
                    <button className="signin-btn">Go to Dashboard</button>
                  </SignedIn> */}
                {!user && (
                  <SignInButton
                    redirectUrl={redirectUrl}
                    children={
                      <button className="primary-variant solid-bg">
                        Sign In Here
                      </button>
                    }
                  />
                )}
              </div>

              {/* <div className="profile-preview">
                  <Card sx={{ maxWidth: 224}}>
                    <div className="p-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <ul className="list-inline mb-0">
                          <li className="list-inline-item"><img src={iconUsFlag} alt="flag" /></li>
                          <li className="list-inline-item"><span className="stock-name">Apple</span></li>
                        </ul>
                        <ul className="list-inline mb-0">
                          <li className="list-inline-item"><span className="stock-code">NASDAQ: APPL</span></li>
                        </ul>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        <img src={appleLogo} className="my-2" alt="apple-logo" width={24}/>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="stock-value">
                          <span className="value">191.24 USD</span>
                          <span className="positive"><img src={iconArrowUp} alt="arrow-up" width={8} />$0.37 (1.56%)</span>
                        </div>
                      </div>
                    </div>
                    <CardContent className="p-3">
                      <span className="stock-description">
                        Apple,Inc. engages in the design, manufacture, and sale of smartphones, personal computers, tablets, wearables and accessories, and other varieties of related services...
                      </span>
                      <div className="d-flex justify-content-center align-items-center">
                        <img src={heartActive} className="my-1" alt="heart" />
                      </div>
                    </CardContent>
                  </Card>
                </div> */}
            </Grid>
            <Grid item md={5} sm={12}>
              <img src={Superhero} alt="superhero" className="img-fluid" />
            </Grid>
          </Grid>
          <Grid>
            <Grid item md={12}>
              <Box component="footer" className="sb-footer my-3">
                <div>
                  <img
                    src={msStartup}
                    className="ms-logo"
                    alt="Microsoft Startup founder hub"
                  />
                </div>
                <div>Data provided by Financial Modeling Prep</div>
                <ul className="list-inline mb-0">
                  <li className="list-inline-item">
                    <a href={ApplicationRoutes.Privacy}>Privacy Policy</a>
                  </li>
                  <li className="list-inline-item">
                    <a href={ApplicationRoutes.Terms}>Terms of Use</a>
                  </li>
                  <li className="list-inline-item">
                    <a href={ApplicationRoutes.Disclaimer}>Disclaimer</a>
                  </li>
                  <li className="list-inline-item">
                    <a href={ApplicationRoutes.Faq}>FAQ</a>
                  </li>
                  <li className="list-inline-item">
                    <span className="cursor-pointer" onClick={handleClickOpen}>
                      Contact Us
                    </span>
                  </li>
                </ul>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
      <>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          maxWidth={"xs"}
          open={open}
        >
          <DialogContent dividers>
            <div className="d-flex mb-3 justify-content-between align-items-center">
              <div className="primary-header-h4">Contact Us</div>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div>
              <h5 className="primary-txt-16 mb-3">
                If you have any questions, You can contact us:
              </h5>
              <ul>
                <li>
                  By email:{" "}
                  <a href="mailto:support@stockbruh.com">
                    support@stockbruh.com
                  </a>
                </li>
              </ul>
            </div>
          </DialogContent>
        </Dialog>
      </>
    </div>
  );
}
export default Landing;
