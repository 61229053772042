import React, { useEffect } from 'react';
import { BrowserRouter } from "react-router-dom";
import Router from "./pages/router";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingProvider } from "./components/loader/loading-context";
import mixpanel from 'mixpanel-browser';
const MIXPANEL_TOKEN = 'a3e1692e28a0095f8e6867f57ad5eee3';

function App() {
 useEffect(() => {
    // Initialize Mixpanel with your project token
    mixpanel.init(MIXPANEL_TOKEN, {
      debug: true,  // Enable debug mode for development
      track_pageview: true
    });

    // Track an event when the page is loaded
    mixpanel.track('Page Loaded');
  }, []);
  return (
    <LoadingProvider>
      <BrowserRouter>
        <ToastContainer />
        <Router />
      </BrowserRouter>
    </LoadingProvider>
  );
}

export default App;
