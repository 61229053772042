import { Navigate, Route, Routes } from "react-router-dom";
import Landing from "./Landing/LandingPage";
import Stock from "./Dashboard/stocksPage";
import PrivacyPage from "./Static/Privacy";
import TermsPage from "./Static/Terms";
import Disclaimer from "./Static/disclaimer";
import FavouritePage from "./Favourite/favourite";
import { useUser } from "@clerk/clerk-react";
import StockProfilePage from "./StockProfile/stockProfilePage";
import { useLocation } from "react-router-dom";
import FaqPage from "./Static/Faq";
import React from "react";
import Spinner from "../components/loader/spinner";
import { ApplicationRoutes } from "../constants/application-routes";
interface ProtectedRouteProps {
  element: JSX.Element;
  isSignedIn: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  element,
  isSignedIn,
}) => {
  const location = useLocation();
  return isSignedIn ? element : <Navigate to="/" state={location.pathname} />;
};

export default function Router() {
  const { isSignedIn, isLoaded: isAuthVerified } = useUser();

  if (!isAuthVerified) {
    return <Spinner />;
  }

  return (
    <Routes>
      <Route path={ApplicationRoutes.Default} element={<Landing />} />
      <Route path={ApplicationRoutes.Privacy} element={<PrivacyPage />} />
      <Route path={ApplicationRoutes.Terms} element={<TermsPage />} />
      <Route path={ApplicationRoutes.Disclaimer} element={<Disclaimer />} />
      <Route path={ApplicationRoutes.Faq} element={<FaqPage />} />

      <Route
        path={ApplicationRoutes.Home}
        element={
          <ProtectedRoute element={<Stock />} isSignedIn={isSignedIn!} />
        }
      />
      <Route
        path={ApplicationRoutes.Favorites}
        element={
          <ProtectedRoute
            element={<FavouritePage />}
            isSignedIn={isSignedIn!}
          />
        }
      />
      <Route
        path={ApplicationRoutes.Profile}
        element={
          <ProtectedRoute
            element={<StockProfilePage />}
            isSignedIn={isSignedIn!}
          />
        }
      />

      {/* Catch-all Route for 404
      <Route path="*" element={<NotFoundPage />} /> */}
    </Routes>
  );
}
