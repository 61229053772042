import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import appRouteHelper from "../utils/app-route-helper";
import { Card, CardContent, Grid, Tooltip, Typography } from "@mui/material";
import useApi from "../../useApi";
import { EtfPeerInfo } from "./model/etf-info";
import { useEffect } from "react";
import useState from "react-usestateref";
import React from "react";
export default function EtfPeers(props: any) {
  const navigate = useNavigate();
  const { getDetailRoute } = appRouteHelper();
  const { sendRequest } = useApi();
  // eslint-disable-next-line
  const [etfPeersInfo, setEtfPeersInfo, etfPeersInfoRef] = useState<
    EtfPeerInfo[]
  >([]);
  useEffect(() => {
    const handleGetApiCall = async <T,>(
      path: string,
      callback: (data: T) => void
    ) => {
      try {
        const response = await sendRequest(path);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        callback(data);
      } catch (error) {}
    };
    const getEtfPeers = () => {
      handleGetApiCall<EtfPeerInfo[]>(
        "/api/stockProfileDetails/etf-peers?symbol=" + props?.symbol,
        (data) => {
          setEtfPeersInfo(data);
        }
      );
    };
    getEtfPeers(); // eslint-disable-next-line
  }, []);
  const onPeerClick = (symbol: string) => {
    const url = getDetailRoute(symbol);
    navigate(url);
  };
  const etfStockSlider = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "10px",
    arrows: true,
    slidesToShow: 6,
    speed: 500,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {etfPeersInfoRef?.current?.length > 0 && (
        <Grid container justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography variant="h5" className="mb-2">
              Stocks in the ETF 
            </Typography>
            <div className="slider-container variant-2">
              <Slider {...etfStockSlider}>
                {etfPeersInfoRef?.current
                  ?.filter((x) => x?.asset)
                  .map((item, index) => (
                    <div
                      className="h-100"
                      key={index}
                      onClick={() => onPeerClick(item.asset)}
                    >
                      <Card sx={{ m: 1}}>
                        <div className="mx-auto p-3 d-flex justify-content-center">
                          <img
                            src={
                              "https://financialmodelingprep.com/image-stock/" +
                              item?.asset +
                              ".png"
                            }
                            alt={item?.name}
                            className="object-fit-contain"
                            width={64}
                            height={64}
                          />
                        </div>
                        <CardContent>
                          {/* <div className="text-center">
                            <div className="primary-header-h6 mb-1">
                              {item?.name}
                            </div>
                          </div> */}
                          <div className="text-center">
                            <div className="primary-header-h6 text-truncate mb-1">
                              <Tooltip title={item?.name} arrow>
                                <span>{item?.name}</span>
                              </Tooltip>
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  ))}
              </Slider>
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
}
