import { useAuth } from "@clerk/clerk-react";
import { useLoading } from "./components/loader/loading-context";

interface UseApi {
  sendRequest: (url: string, options?: RequestInit) => Promise<Response>;
  patchRequest: (
    url: string,
    body: any,
    options?: RequestInit
  ) => Promise<Response>;
  postRequest: (
    url: string,
    body: any,
    options?: RequestInit
  ) => Promise<Response>;
  deleteRequest: (url: string, options?: RequestInit) => Promise<Response>;
}

const useApi = (): UseApi => {
  const { getToken } = useAuth(); // eslint-disable-next-line
  const { increaseLoadingCount, decreaseLoadingCount, loadingCount } =
    useLoading();

  const sendRequest = async (
    url: string,
    options: RequestInit = {},
    hideLoader: boolean = false
  ): Promise<Response> => {
    const token = await getToken({ template: "stockbruh" });

    const headers = new Headers(options.headers || {});
    headers.append("Authorization", `Bearer ${token}`);
    const apiUrl =
      `https://${process.env.REACT_APP_CLERK_ACCOUNTS_REDIRECT_HOST}` + url;

    if (hideLoader) {
      return fetch(apiUrl, { ...options, headers });
    }
    increaseLoadingCount();
    const response = await fetch(apiUrl, { ...options, headers });
    setTimeout(() => decreaseLoadingCount(), 1000);
    return response;
  };

  const postRequest = async (
    url: string,
    body: any,
    options: RequestInit = {}
  ): Promise<Response> => {
    const token = await getToken({ template: "stockbruh" });

    const headers = new Headers(options.headers || {});
    headers.append("Authorization", `Bearer ${token}`);
    headers.append("Content-Type", "application/json");
    const apiUrl =
      `https://${process.env.REACT_APP_CLERK_ACCOUNTS_REDIRECT_HOST}` + url;
    return fetch(apiUrl, {
      ...options,
      headers,
      method: "POST",
      body: JSON.stringify(body),
    });
  };

  const patchRequest = async (
    url: string,
    body: any,
    options: RequestInit = {}
  ): Promise<Response> => {
    const token = await getToken({ template: "stockbruh" });

    const headers = new Headers(options.headers || {});
    headers.append("Authorization", `Bearer ${token}`);
    headers.append("Content-Type", "application/json");
    const apiUrl =
      `https://${process.env.REACT_APP_CLERK_ACCOUNTS_REDIRECT_HOST}` + url;
    return fetch(apiUrl, {
      ...options,
      headers,
      method: "PATCH",
      body: JSON.stringify(body),
    });
  };

  const deleteRequest = async (
    url: string,
    options: RequestInit = {}
  ): Promise<Response> => {
    const token = await getToken({ template: "stockbruh" });

    const headers = new Headers(options.headers || {});
    headers.append("Authorization", `Bearer ${token}`);

    return fetch(url, { ...options, headers, method: "DELETE" });
  };

  return {
    sendRequest,
    patchRequest,
    deleteRequest,
    postRequest,
  };
};

export default useApi;
