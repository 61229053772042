import EtfProfile from "./etfProfile";
import StockProfile from "./stockProfile";
import { useParams } from "react-router-dom";
import { CompanyProfile } from "../../models/StockProfile";
import { CompanyOutlookResponse } from "./model/companyoutlook/company-outlook-response";
import useApi from "../../useApi";
import { useEffect, useState } from "react";
import { Box, Dialog, DialogContent, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
export default function StockProfileLayout() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // eslint-disable-next-line
  const { symbol } = useParams();
  const { sendRequest } = useApi();
  // eslint-disable-next-line
  const [companyOutLook, setCompanyOutlook] = useState(
    {} as CompanyOutlookResponse
  );
  // eslint-disable-next-line
  const [
    // eslint-disable-next-line
    profileLoader, // eslint-disable-next-line
    setProfileLoader,
  ] = useState(false);
  // eslint-disable-next-line
  const [
    // eslint-disable-next-line
    companyProfile, // eslint-disable-next-line
    setCompanyProfile,
  ] = useState({} as CompanyProfile);
  let peersDetails = [] as any;
  const [
    // eslint-disable-next-line
    peersList, // eslint-disable-next-line
    setPeersList,
  ] = useState(peersDetails);

  useEffect(() => {
    const handleGetApiCall = async <T,>(
      path: string,
      callback: (data: T) => void
    ) => {
      try {
        const response = await sendRequest(path);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        callback(data);
      } catch (error) {}
    };
    const getCompanyProfile = () => {
      setProfileLoader(false);
      handleGetApiCall<CompanyProfile>(
        "/api/stockProfiles/profileBySymbol?symbol=" + symbol?.toUpperCase(),
        (data) => {
          setCompanyProfile(data);
          if (!(data?.isEtf || data?.isFund)) {
            loadPeersLogo(data?.peers);
            getCompanyOutLook();
          } else {
            setProfileLoader(true);
          }
        }
      );
    };
    const getCompanyOutLook = () => {
      handleGetApiCall<CompanyOutlookResponse>(
        "/api/stockProfileDetails/companyOutlook?symbol=" +
          symbol?.toUpperCase(),
        (data) => {
          setCompanyOutlook(data);
          setProfileLoader(true);
        }
      );
    };
    const loadPeersLogo = (peers?: string[]) => {
      const peerLists: any[] = [];
      // eslint-disable-next-line
      peers?.forEach((peer) => {
        handleGetApiCall<CompanyProfile>(
          "/api/stockProfiles/profileBySymbol?symbol=" + peer,
          (data) => {
            peerLists.push({
              imgSrc: data?.image,
              name: data?.companyName,
              symbol: data?.Symbol,
              isEtf: data?.isEtf,
              metrics: data?.metrics,
            });
            setPeersList(peerLists);
          }
        );
      });
    };
    getCompanyProfile(); // eslint-disable-next-line
  }, [symbol]); // eslint-disable-next-line
  return profileLoader ? (
    <div>
      {(companyProfile?.isEtf || companyProfile?.isFund) && (
        <EtfProfile stock={companyProfile} peers={peersList} />
      )}
      {!companyProfile?.isEtf && (
        <StockProfile
          stock={companyProfile}
          companyOutLook={companyOutLook}
          companyOutlookLoader={companyProfile}
          peers={peersList}
        />
      )}
      <Grid>
        <Box component="footer" className="sb-footer my-3">
          <div style={{ color: "#ccc" }}>
            Data provided by Financial Modeling Prep
          </div>
          <ul className="list-inline mb-0">
            <li className="list-inline-item">
              <a href="/privacy" style={{ color: "#ccc" }}>
                Privacy Policy
              </a>
            </li>
            <li className="list-inline-item">
              <a href="/terms" style={{ color: "#ccc" }}>
                Terms of Use
              </a>
            </li>
            <li className="list-inline-item">
              <a href="/" style={{ color: "#ccc" }}>
                Disclaimer
              </a>
            </li>
            <li className="list-inline-item">
              <a href="/faq" style={{ color: "#ccc" }}>
                FAQ
              </a>
            </li>
            <li className="list-inline-item">
              <span
                className="cursor-pointer"
                style={{ color: "#ccc" }}
                onClick={handleClickOpen}
              >
                Contact Us
              </span>
            </li>
          </ul>
        </Box>
      </Grid>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth={"xs"}
        open={open}
      >
        <DialogContent dividers>
          <div className="d-flex mb-3 justify-content-between align-items-center">
            <div className="primary-header-h4">Contact Us</div>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div>
            <h5 className="primary-txt-16 mb-3">
              If you have any questions, You can contact us:
            </h5>
            <ul>
              <li>
                By email:{" "}
                <a href="mailto:support@stockbruh.com">support@stockbruh.com</a>
              </li>
            </ul>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  ) : (
    <div></div>
  );
}
