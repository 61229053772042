export const discoverCategories = ["ETF/Funds", "Stocks"];
export const sectors = [
  "Consumer Cyclical",
  "Energy",
  "Technology",
  "Industrials",
  "Financial Services",
  "Basic Materials",
  "Consumer Defensive",
  "Healthcare",
  "Real Estate",
  "Utilities",
];

export type MetricsType =
  | "growth"
  | "cash"
  | "profitability"
  | "earnings"
  | "returns"
  | "price"
  | "dividend";

export type MetricsDescriptionType = { [key in MetricsType]: string };

export const MetricsDescription: MetricsDescriptionType = {
  cash: "Cash",
  dividend: "Dividends",
  earnings: "Earnings",
  growth: "Growth",
  price: "Price",
  profitability: "Profits",
  returns: "Returns",
};

export enum WeatherType {
  cloudy = "Cloudy",
  sunny = "Sunshine",
  thunderstorm = "Thunderstorm",
}
